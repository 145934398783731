<template>
  <div>
    <h1>Terima Kasih</h1>
    <h2>sesi telekonsul telah berakhir</h2>
  </div>
</template>

<script>
export default {
  mounted() {
    window.CHANNEL_NAME.postMessage('end call')
  }
}
</script>

<style>

</style>